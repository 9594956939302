<template>
  <div>
    <primaryButton
      v-if="functionElement"
      class="mr-2"
      :attributesArray="functionElement['attributesArray']"
      type="button"
      :value="functionElement['functionName']"
      @click="printContent"
      :label="functionElement['functionName']"
      color="save"
      :disabled="!isValid || loading"
      :loading="loading && isValid"
      :icon="'mdi-printer'"
    ></primaryButton>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  props: ["isValid", "loading", "functionElement", "result"],
  components: {
    primaryButton,
  },
  methods: {
    printContent() {
      var mywindow = window.open("", "Acctopus Degust", "");
      mywindow.document.write(
        "<html><head><title>Acctopus 2FA Recovery Code</title>"
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write("<h3>Acctopus 2FA Recovery Code</h3>");
      // mywindow.document.write(divToPrint.html());
      mywindow.document.write(this.result.valueLine.recoveryCode.join("<br>"));
      mywindow.document.write("</body></html>");
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
      return true;
    },
  },
};
</script>